import React from 'react'

import Project1 from '../Assets/project_1.png';
import Project2 from '../Assets/project_2.png';

const Cards = () => {
  return (
    <div className='container-cards'>
        <a className='container-1' target='_blank' href='https://trillionairethugs.com/'>
            <img src={Project1} alt="tthugs"/>
            <p className='description'>TRILLIONAIRE THUGS<br/>NFT COLLECTION</p>
        </a>
        <div className='container-2'>
            <img src={Project2} alt="tthugs"/>
            <p className='description'>TRILLIONAIRE THUGS<br/>P2E GAME</p>
        </div>
    </div>
  )
}

export default Cards