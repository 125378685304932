import React from 'react'

import LogoSrc from '../Assets/nerawave.png';

const Logo = () => {
  return (
    <img src={LogoSrc} className="Logo" alt="logo"/>
  )
}

export default Logo