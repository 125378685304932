import './App.css';

import Logo from './Components/Logo';
import Topbar from './Components/TopBar.js';
import MidBar from './Components/MidBar.js';
import Cards from './Components/Cards.js';

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import React from 'react';

const App = () => {
  const particlesInit = async (main) => {
    console.log(main);
    await loadFull(main);
  }
  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <div className="App">
      <div className="particle-index">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={params}
          canvasClassName=''
          height='100vh'
          width='100vw' 
        />
      </div>
      <div style={{position: 'absolute', width: '100vw', height: '100%'}}>
        <Topbar />
        <Logo />
        <MidBar />
        <Cards />
      </div>
    </div>
  );
}

const params = {
    fpsLimit: 120,
    fullScreen: {
      enable: true,
      zIndex: 0,
    },
    interactivity: {
      events: {
        resize: true,
      },
      modes: {
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: "#6912fe",
      },
      links: {
        color: "#6912fe",
        distance: 150,
        enable: true,
        opacity: 0.2,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: false,
        speed: 2,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 30,
      },
      opacity: {
        value: 0.2,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
}
export default App;
