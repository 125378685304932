import React from 'react'

const MidBar = () => {
  return (
    <div className='container-mid-bar'>
        <div className='bar left'></div>
        <p className='text-mid-bar'>WELCOME TO WEB3</p>
        <div className='bar right'></div>
    </div>
  )
}

export default MidBar